// import

import type {MetaFunction} from '@remix-run/react';

import {Link} from '@remix-run/react';

import {env} from '~/libs/env';
import {ErrorView} from '~/modules/ErrorView';

// config

export const meta: MetaFunction = () => {
  return [
    {title: 'Not Found 🐾 BrowserCat'},
    {name: 'robots', content: 'noindex'},
  ];
};

export const loader = async () => {
  return new Response(null, {
    status: 404,
    statusText: 'Not Found',
  });
};

// export

export default function NotFound() {
  return (
    <ErrorView title="Not found!">
      <div className="prose">
        <p>We can't find what your looking for. What a <span className="whitespace-nowrap"><em>cat</em>-astrophy</span>!</p>

        <p>Please check the URL for typos, visit your <Link to="/">account dashboard</Link>, or pop over to the BrowserCat <Link target="_blank" to={env.wwwUrl}>homepage</Link>.</p>
      </div>
    </ErrorView>
  );
}
